/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

#DevThemeView {
  .panel-body {
    background-color: $body-bg;
  }

  .panel-body > p:last-child,
  .panel-body > ul:last-child,
  .panel-body > ol:last-child,
  .panel-body > blockquote:last-child,
  .panel-body > .form-control:last-child,
  .panel-body > .table:last-child,
  .panel-body > .navbar:last-child,
  .panel-body > .jumbotron:last-child,
  .panel-body > .alert:last-child,
  .panel-body > .panel:last-child,
  .panel-body > .list-group:last-child,
  .panel-body > .well:last-child,
  .panel-body > .progress:last-child,
  .panel-body > .table-responsive:last-child > .table {
    margin-bottom: 0;
  }

  .dropdown-menu {
    position: static;
  }

  .putit-style-guide-modal {
    background-color: rgba(0, 0, 0, 0.2);
  }

  .putit-style-guide-modal .modal {
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    z-index: 1;
    display: block;
  }

  .putit-style-guide-modal .modal-dialog {
    left: auto;
    margin-right: auto;
    margin-left: auto;
  }

  .dropdown-menu,
  .modal-content {
    box-shadow: none !important;
  }

  .label-wrapper + .label-wrapper {
    margin-left: 1em;
  }

  .putit-loader {
    margin-top: 0px;

    > p {
      margin-bottom: 0px;
    }
  }
}
