/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.putit-loader {
  margin: 0px auto;
  margin-top: $line-height-computed;
  width: 315px;

  > p {
    margin-top: $line-height-computed;
  }

  > svg {
    filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.2));
    vertical-align: top;
    width: 315px;

    .shape-n {
      fill: $brand-success;
    }

    .shape-ne {
      fill: $brand-danger;
    }

    .shape-se {
      fill: $brand-warning;
    }

    .shape-s {
      fill: $label-default-bg;
    }

    .text {
      fill: $gray-darker;
    }
  }

  &.animate {
    .shape-n {
      animation: anim-shape-n linear 1s infinite;
    }

    .shape-ne {
      animation: anim-shape-ne linear 1s infinite;
    }

    .shape-se {
      animation: anim-shape-se linear 1s infinite;
    }

    .shape-s {
      animation: anim-shape-s linear 1s infinite;
    }

    @keyframes anim-text {
      0% {
        opacity: 0.2;
      }

      50% {
        opacity: 1;
      }

      100% {
        opacity: 0.2;
      }
    }

    @keyframes anim-shape-n {
      0% {
        opacity: 0.2;
      }

      20% {
        opacity: 1;
      }

      40% {
        opacity: 0.2;
      }

      60% {
        opacity: 0.2;
      }

      80% {
        opacity: 0.2;
      }

      100% {
        opacity: 0.2;
      }
    }

    @keyframes anim-shape-ne {
      0% {
        opacity: 0.2;
      }

      20% {
        opacity: 0.2;
      }

      40% {
        opacity: 1;
      }

      60% {
        opacity: 0.2;
      }

      80% {
        opacity: 0.2;
      }

      100% {
        opacity: 0.2;
      }
    }

    @keyframes anim-shape-se {
      0% {
        opacity: 0.2;
      }

      20% {
        opacity: 0.2;
      }

      40% {
        opacity: 0.2;
      }

      60% {
        opacity: 1;
      }

      80% {
        opacity: 0.2;
      }

      100% {
        opacity: 0.2;
      }
    }

    @keyframes anim-shape-s {
      0% {
        opacity: 0.2;
      }

      20% {
        opacity: 0.2;
      }

      40% {
        opacity: 0.2;
      }

      60% {
        opacity: 0.2;
      }

      80% {
        opacity: 1;
      }

      100% {
        opacity: 0.2;
      }
    }
  }

  &.inactive {
    .shape-n,
    .shape-ne,
    .shape-se,
    .shape-s {
      opacity: 0.2;
    }
  }

  &.variant-small {
    text-align: center;
    width: auto;

    > p,
    > svg {
      display: inline-block;
    }

    > p {
      line-height: 30px;
      margin: 0px;
      margin-bottom: 0px;
      margin-right: 1em;
    }

    > svg {
      height: 30px;
      width: auto;
    }
  }
}
