/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

#Navbar {
  left: 285px;
  border-radius: 0 0 0 5px;
  background: #fff;

  .navbar-nav {
    > li > a {
      background: #fff;
      color: $gray-darker;
      font-family: 'Oxygen Regular';
      font-size: medium;
    }

    > .active > a {
      &,
      &:hover,
      &:focus {
        @include text-bold;
        border-bottom: 5px solid $putit-color-blue;
      }
    }

    #NavbarLogin {
      padding-top: 7px;
      padding-bottom: 7px;
    }
  }

  .putit-nav-search {
    .nav {
      margin-left: $navbar-padding-horizontal;

      a {
        background-color: $brand-success;
        color: white;
        border: 0px none !important;
        padding-left: 0px;
        padding-right: 0px;
        text-align: center;
        width: $navbar-height;
      }
    }

    .navbar-form {
      background: #fff;
      overflow: hidden;
      padding-left: 0px;
      padding-right: 0px;
      width: 0px;
    }

    &.expanded {
      .nav {
        margin-left: 0px;
      }

      .navbar-form {
        padding-left: $navbar-padding-horizontal;
        padding-right: $navbar-padding-horizontal;
        width: auto;
      }
    }
  }
}
