.ReleaseAddNewChange {
  .putit-table {
    background-color: #fff;
    border-radius: 5px;

    .header {
      padding-top: 20px;
      font-size: large;

      .row {
        border-bottom: 2px solid #e4e4e4;
        border-radius: 0;
        height: 66px;
      }

      .name {
        padding-top: 8px;
      }
    }

    form.release-add-change {
      .grey-text {
        color: #b6b6b6;
        text-transform: uppercase;
      }
      input {
        font-size: medium;
      }

      select {
        border: 0px;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 0px;
        background-image: linear-gradient(#3da8cc, #3da8cc);
        background-position: bottom 7px center;
        background-size: 93% 1px;
        background-repeat: no-repeat;
        padding-bottom: 4px;
        font-size: inherit;
      }

      .row {
        height: 88px;
        border-bottom: 0;
        border-radius: 5px;
        overflow: visible;
      }
      .form-control {
        padding-left: 0;
        background-size: 100% 1px;
      }

      .date-row {
        .nice-dates-day::before,
        .nice-dates-day::after {
          background-color: $putit-color-blue;
          border: 2px solid $putit-color-blue;
          border-radius: 5px;
        }

        .nice-dates-day.-selected.-selected-start::before {
          border-radius: 5px 0 0 5px;
        }

        .nice-dates-day.-selected.-selected-middle::before {
          border-radius: 0;
        }

        .nice-dates-day.-selected.-selected-end::before {
          border-radius: 0 5px 5px 0;
        }

        .nice-dates-popover {
          max-width: 280px;
          transition: none;
          border: 1px solid $putit-color-blue;
        }

        .start-date,
        .end-date {
          width: 130px;
        }

        .date-button {
          padding-left: 37px;
        }
      }

      .delete {
        @include use-icon('ICON_Delete_Blue');
        width: 22px;
        height: 15px;
        display: inline-block;
        top: 3px;
        position: relative;
        cursor: pointer;
      }

      .approvers-list {
        margin-top: -23px;
        ul {
          padding-left: 20px;
          li {
            list-style-type: none;
            padding-bottom: 5px;
          }
        }

        .text {
          padding-left: 20px;
        }
      }

      .applications-list {
        ul {
          padding-left: 0px;
          li {
            list-style-type: none;
          }
        }
      }

      .add-application {
        padding-top: 26px;
      }

      .autocomplete-input {
        border: 0px;
        padding-top: 10px;
        background-image: linear-gradient(#3da8cc, #3da8cc);
        background-position: bottom 7px center;
        background-size: 100% 1px;
        background-repeat: no-repeat;
        padding-bottom: 11px;
        outline: 0;
        width: 100%;
      }

      .autocomplete-list {
        padding: 0;
        margin-top: 0;
        position: absolute;
        background-color: white;
        width: 100%;
        max-height: 20rem;
        overflow-y: auto;
        overflow-x: hidden;
        outline: 0;
        transition: opacity 0.1s ease;
        z-index: 1;
      }

      .autocomplete-item:hover {
        background-color: $putit-color-blue;
        cursor: pointer;
      }

      select:required:invalid {
        color: #9e9e9e;
      }
      .jira-button {
        padding-top: 20px;
      }

      .description-row {
        height: 150px;
      }

      .textarea-wrapper {
        textarea {
          border: none;
          border-radius: 0;
          border-bottom: 1px solid $putit-color-blue;
          min-height: 65px;
          height: 65px;
          font-size: medium;
        }
      }
    }
  }
}
