/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@font-face {
  font-family: 'Oxygen Bold';
  src: url('~fonts/oxygen/oxygen-bold-webfont.woff2') format('woff2'),
    url('~fonts/oxygen/oxygen-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Oxygen Light';
  src: url('~fonts/oxygen/oxygen-light-webfont.woff2') format('woff2'),
    url('~fonts/oxygen/oxygen-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Oxygen Regular';
  src: url('~fonts/oxygen/oxygen-regular-webfont.woff2') format('woff2'),
    url('~fonts/oxygen/oxygen-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
