/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

#Sidebar {
  background: #fff;
  bottom: 0px;
  position: fixed;
  left: 0px;
  top: 0px;
  width: $sidebar_width;
  z-index: $zindex-navbar-fixed;

  .logo {
    @include use-sprite('new-sidebar-logo');
    cursor: pointer;
    height: 106px;
    margin-left: 32px;
    margin-top: 20px;
    position: relative;
    width: 198px;
    z-index: $zindex-navbar-fixed + 1;
    background-size: 120px;
    background-position-x: center;
  }

  .widgets {
    &.putit-hidden {
      > .widget {
        display: none;
      }
    }
  }

  .blueprint-widgets {
    .widgets {
      &.blueprint {
        background-color: #fff;
        padding-top: 39px;
        position: relative;

        .widget {
          margin-bottom: 20px;
          position: relative;

          &:last-child {
            margin-bottom: 0px;

            &:after {
              display: none;
            }
          }

          .widget-inner {
            margin: 0px auto;
            min-height: 55px;
            position: relative;
            width: 220px;
            background-color: none;
            border: 1px solid $putit-color-blue;
            border-radius: 5px;
            color: #091920;

            > .widget-title {
              cursor: pointer;
              font-family: 'Oxygen Bold';
              font-size: 18px;
              line-height: 50px;
              margin: 0px;
              padding-left: 68px;
              position: relative;
              z-index: 1;
            }

            > .widget-icon {
              position: absolute;
              display: block;
            }

            > .widget-caret {
              @include use-icon('ICON_Arrow_Down_Blue');
              display: block;
              height: 7px;
              position: absolute;
              right: 16px;
              top: 24px;
              width: 12px;

              &.expanded {
                transform: rotate(180deg);
              }
            }

            > .widget-content {
              display: none;
              padding: 0px 15px;
              padding-bottom: 12px;

              &.expanded {
                display: block;
              }

              > .form-group:last-child {
                margin-bottom: 0px;
              }

              &:empty {
                display: none !important;
              }

              .filter-actions {
                margin-bottom: 0px;
                padding: 0px 10px;
                text-align: center;
                margin-left: -15px;
                margin-right: -15px;
                border-top: 1px solid $putit-color-blue;

                a {
                  @include text-bold;
                  color: $putit-color-blue;
                  display: inline-block;
                  margin-left: 10px;

                  &:first-child {
                    margin-left: 0px;
                    padding-top: 9px;
                  }
                }
              }
            }
          }

          &.disabled {
            opacity: 0.7;

            .widget-title {
              cursor: not-allowed;
            }

            .widget-content {
              display: none !important;
            }
          }
        }
      }
    }
  }
}
