/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.putit-checkbox {
  label {
    line-height: 23px;
    min-height: 23px;
    padding-left: 53px;
  }

  .checkbox-label {
    opacity: 0.5;
  }

  .control {
    @include use-icon('ICON_Checkbox_Empty');
    display: block;
    height: 14px;
    position: absolute;
    margin-left: -44px;
    width: 27px;
    top: 5px;

    &.checked {
      + .checkbox-label {
        opacity: 1;
      }
      @include use-icon('ICON_Checkbox_Full');
    }

    &.disabled {
      @include use-sprite('checkbox-disabled');
    }

    &.disabled.checked {
      @include use-sprite('checkbox-disabled-checked');
    }
  }
}

.putit-select {
  .form-control {
    margin-right: 12px;
    border-radius: 5px;
    padding-left: 15px;
    background-color: #fff;
  }

  .select-caret {
    @include use-icon('ICON_Arrow_Down_Blue');
    height: 6px;
    position: absolute;
    right: 10px;
    top: 20px;
    width: 12px;
  }

  &.disabled {
    .form-control {
      background-color: $input-bg-disabled;
      border-color: $input-border-focus;
      color: $input-color-disabled;
      cursor: $cursor-disabled;
      opacity: 1;
    }
  }

  span {
    // @include text-bold;
    cursor: default;
    display: block;
  }
}

.putit-select-dropdown {
  border: 1px solid $putit-color-blue;
  border-radius: 5px;
  color: $putit-color-blue;
  text-align: left;
  background: #fff;
  font-size: $input-font-size;
  line-height: $input-line-height;
  min-height: $input-height-base;
  padding: $input-padding-vertical 0px;
  position: relative;

  ul {
    list-style: none;
    margin: 0px;
    padding-left: 15px;

    li {
      margin-top: $input-padding-vertical;

      &:first-child {
        margin-top: 0px;
      }

      span {
        // @include text-bold;
        cursor: default;
        display: block;
        opacity: 0.5;
        color: #333;

        &:hover {
          opacity: 1;
          color: #333;
        }
      }
    }
  }
}

.putit-input-group {
  @include clearfix;
  position: relative;
  z-index: 0;

  .input-wrapper,
  .putit-input-addon,
  .putit-select {
    float: left;
  }

  .input-wrapper,
  .putit-select {
    width: calc(100% - #{$input-height-base} - #{3 * $input-margin-horizontal});
  }

  .putit-input-addon {
    background-color: $gray-dark;
    height: $input-height-base;
    line-height: $input-height-base;
    margin-right: $input-margin-horizontal;
    min-width: $input-height-base;
    position: relative;
    text-align: center;
    z-index: -1;

    &:before {
      background-color: $gray-dark;
      content: '';
      display: block;
      height: $input-height-base;
      left: (-1 * $input-margin-horizontal);
      position: absolute;
      top: 0px;
      width: $input-margin-horizontal;
    }

    &:after {
      @include use-sprite('input-addon-after');
      content: '';
      display: block;
      height: $input-height-base;
      right: (-1 * $input-margin-horizontal);
      position: absolute;
      top: 0px;
      width: $input-margin-horizontal;
    }

    &.button {
      cursor: pointer;

      .glyphicon {
        top: 0px;
        vertical-align: middle;
      }
    }
  }
}

.putit-required-label {
  .required-symbol {
    @include use-icon('ICON_STATE_Blue');
    background-size: contain;
    height: 12px;
    width: 16px;
    display: inline-block;
    padding-right: 30px;
  }

  .required-symbol.invalid {
    @include use-icon('ICON_STATE_Red');
    background-size: contain;
  }
}
.putit-required-input {
  .required-text {
    color: red;
    font-size: x-small;
    margin-top: -3px;
    position: absolute;
  }
}
