/*!
 * Putit Web | Copyright 2018-present Putit Team <info@putit.io> | Apache 2.0 License
 * https://github.com/putit/putit-web
 */

@mixin use-sprite($name) {
  background: transparent url('~sprite/'+$name+'.png') no-repeat;
}

@mixin use-icon($name) {
  background: transparent url('~sprite/'+$name+'.svg') no-repeat;
}
