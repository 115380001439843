/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.putit-table {
  .head,
  .row {
    @include clearfix;
    margin: 0px;
    position: relative;
    border-radius: 5px;
    overflow: hidden;

    .column {
      float: left;
      width: $app_table_column_width;

      &.empty {
        width: calc(100% - #{$app_table_column_width} - 65px);
      }
    }

    .scrollview {
      float: left;
      overflow: hidden;
      position: relative;
      white-space: nowrap;
      width: calc(100% - #{$app_table_column_width} - 65px);

      > .column {
        display: inline-block;
        float: none;
      }
    }
  }

  .head {
    height: 22px;

    .header {
      height: 24px;
      padding: 0px 14px;
      position: relative;

      > span {
        padding-right: 12px;
        color: #b6b6b6;
        display: block;
        font-family: 'Oxygen Bold';
        line-height: 22px;
        text-align: center;
        text-transform: uppercase;
      }

      &.app-name-header {
        > span {
          color: $putit-color-blue;
          text-transform: none;
        }
      }

      &:not(:empty) {
        @include use-icon('Special_Shape_White');
      }
    }

    .scroller {
      position: absolute;
      height: 18px;
      right: 20px;
      top: 2px;
      width: 30px;

      > span {
        cursor: pointer;
        display: inline-block;
        height: 18px;
        margin-left: 6px;
        width: 12px;

        &:first-child {
          margin-left: 0px;
        }

        &.left {
          @include use-sprite('putit-table-scroller-left');
        }

        &.right {
          @include use-sprite('putit-table-scroller-right');
        }
      }
    }
  }

  .row {
    height: 66px;
    margin-bottom: 25px;

    .column {
      .cell {
        background-color: #fff;
        height: 66px;
        line-height: 66px;
        padding: 0px 64px;
        font-family: 'Oxygen Bold';
        font-size: medium;

        &.clickable {
          cursor: pointer;
        }

        &.hide-text-overflow {
          overflow: hidden;
          text-overflow: ellipsis;
        }

        > span {
          vertical-align: middle;
        }

        &.app-name-cell,
        &.row-header-cell {
          padding: 0px 30px;

          &:empty {
            visibility: hidden;
          }
        }

        &.app-name-cell {
          > span {
            color: black;
            font-family: 'Oxygen Regular';
          }
        }

        &.row-header-cell {
          > span {
            color: black;
            font-family: 'Oxygen Regular';
            font-size: medium;
          }
        }

        &.label-cell,
        &.icon-cell,
        &.glyphicon-cell {
          > span:first-child {
            display: inline-block;
            text-align: left;
            width: 95px;
            font-family: 'Oxygen Bold';
            font-size: medium;
          }
        }

        &.glyphicon-cell {
          > span.glyphicon {
            height: 22px;
            font-size: 22px;
            display: inline-block;
            width: 25px;
          }
        }

        &.segmented-control-cell {
          ul {
            margin: 0px;

            li {
              display: inline;

              &:before {
                content: '|';
                display: inline;
                color: $text-color;
                padding: 0px 10px;
              }

              &:first-child {
                &:before {
                  display: none;
                }
              }
            }
          }
        }

        &.loader-cell {
          line-height: 30px;
          padding-bottom: 18px;
          padding-top: 18px;

          .putit-loader {
            margin-top: 0px;
          }
        }
      }
    }

    .scrollview {
      &:before {
        background-color: #fff;
        content: '';
        display: block;
        left: 0px;
        position: absolute;
        height: 66px;
        right: 0px;
        top: 0px;
        z-index: -1;
      }
    }

    .action-button-cog {
      @include use-icon('ICON_Options');
      display: inline-block;
      cursor: pointer;
      height: 27px;
      width: 23px;
    }

    > .actions {
      background-color: #fff;
      float: left;
      height: 66px;
      line-height: 66px;
      text-align: center; // FIXME
      width: 54px;

      .action-button-plus {
        @include use-icon('ICON_More_Blue');
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
        height: 27px;
        width: 23px;
      }
    }

    .action-button-left-arrow-wrapper,
    .action-button-right-arrow-wrapper {
      background-color: $putit-color-blue;
      display: inline-block;
      position: relative;
      height: 66px;
      line-height: 66px;
      width: 11px;
      cursor: pointer;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;

      .scroller {
        display: block;
        height: 100%;
        width: 100%;
        overflow: hidden;

        span {
          display: block;
          height: 100%;
          width: 100%;
          overflow: hidden;

          .action-button-right-arrow {
            @include use-icon('ICON_Arrow_Right_White');
            position: relative;
            left: 2px;
            display: inline-block;
            height: 11px;
            width: 11px;
          }
        }
      }
    }

    .action-button-left-arrow-wrapper {
      margin-left: -11px;
      float: left;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;

      .scroller {
        span {
          .action-button-left-arrow {
            @include use-icon('ICON_Arrow_Left_White');
            position: relative;
            left: 1px;
            display: inline-block;
            height: 11px;
            width: 11px;
          }
        }
      }
    }
  }
}
