/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

table {
  border: 0px none !important;

  td {
    background-color: $gray-light;
    border: 0px none !important;
  }

  th {
    background-color: $gray-light;
    @include text-bold;
    border: 0px none !important;
    color: #b6b6b6;
    text-align: center;
    text-transform: uppercase;

    &:empty {
      background: transparent;
    }
  }
}

.form-group {
  .input-wrapper,
  .textarea-wrapper,
  .putit-select {
    position: relative;
  }

  .input-wrapper,
  .putit-select {
    background-color: #fff;
    border: 1px solid $putit-color-blue;
    border-radius: 5px;
    color: $putit-color-blue;
    text-align: left;
  }

  .input-wrapper {
    border: 0px;
    .form-control {
      border: 0px;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 0px;

      background-image: linear-gradient(#3da8cc, #3da8cc);
      background-position: bottom 7px center;
      background-size: 93% 1px;
      background-repeat: no-repeat;
      padding-bottom: 4px;
    }
  }

  .textarea-wrapper {
    z-index: 0;

    textarea {
      max-width: 100%;
      min-width: 100%;
    }

    .textarea-left,
    .textarea-right {
      background: $input-bg;
      display: block;
      bottom: ($input-height-base / 2);
      position: absolute;
      top: ($input-height-base / 2);
      width: $input-margin-horizontal;

      &:before,
      &:after {
        background: blue;
        content: '';
        display: block;
        height: $input-height-base;
        position: absolute;
        width: $input-margin-horizontal;
        z-index: -1;
      }
    }

    .textarea-left {
      border-left: 1px solid $input-border;
      left: (-1 * $input-margin-horizontal);

      &:before {
        @include use-sprite('input-normal-before');
        left: -1px;
        top: (-1 * $input-height-base / 2);
      }

      &:after {
        @include use-sprite('input-normal-before');
        bottom: (-1 * $input-height-base / 2);
        left: -1px;
      }
    }

    .textarea-right {
      border-right: 1px solid $input-border;
      right: (-1 * $input-margin-horizontal);

      &:before {
        @include use-sprite('input-normal-after');
        right: -1px;
        top: (-1 * $input-height-base / 2);
      }

      &:after {
        @include use-sprite('input-normal-after');
        bottom: (-1 * $input-height-base / 2);
        right: -1px;
      }
    }

    &.focus {
      .textarea-left {
        border-color: $input-border-focus;

        &:before,
        &:after {
          @include use-sprite('input-focus-before');
        }
      }

      .textarea-right {
        border-color: $input-border-focus;

        &:before,
        &:after {
          @include use-sprite('input-focus-after');
        }
      }
    }

    &.disabled {
      .textarea-left {
        background-color: $input-bg-disabled;
        border-color: $input-border-focus;

        &:before,
        &:after {
          @include use-sprite('input-disabled-before');
        }
      }

      .textarea-right {
        background-color: $input-bg-disabled;
        border-color: $input-border-focus;

        &:before,
        &:after {
          @include use-sprite('input-disabled-after');
        }
      }
    }
  }

  &.has-error {
    .input-wrapper,
    .putit-select {
      &:before {
        @include use-sprite('input-danger-before');
      }

      &:after {
        @include use-sprite('input-danger-after');
      }
    }

    .textarea-wrapper {
      .textarea-left {
        border-color: $state-danger-bg;

        &:before,
        &:after {
          @include use-sprite('input-danger-before');
        }
      }

      .textarea-right {
        border-color: $state-danger-bg;

        &:before,
        &:after {
          @include use-sprite('input-danger-after');
        }
      }
    }
  }

  &.has-warning {
    .input-wrapper,
    .putit-select {
      &:before {
        @include use-sprite('input-warning-before');
      }

      &:after {
        @include use-sprite('input-warning-after');
      }
    }

    .textarea-wrapper {
      .textarea-left {
        border-color: $state-warning-bg;

        &:before,
        &:after {
          @include use-sprite('input-warning-before');
        }
      }

      .textarea-right {
        border-color: $state-warning-bg;

        &:before,
        &:after {
          @include use-sprite('input-warning-after');
        }
      }
    }
  }

  &.has-success {
    .input-wrapper,
    .putit-select {
      &:before {
        @include use-sprite('input-success-before');
      }

      &:after {
        @include use-sprite('input-success-after');
      }
    }

    .textarea-wrapper {
      .textarea-left {
        border-color: $state-success-bg;

        &:before,
        &:after {
          @include use-sprite('input-success-before');
        }
      }

      .textarea-right {
        border-color: $state-success-bg;

        &:before,
        &:after {
          @include use-sprite('input-success-after');
        }
      }
    }
  }

  .input-wrapper,
  .textarea-wrapper {
    .fake-placeholder {
      @include user-select(none);
      color: $input-color-placeholder;
      cursor: text;
      display: block;
      height: $input-height-base - 2px;
      padding: $input-padding-vertical 0px;
      font-size: $input-font-size;
      left: 0px;
      line-height: $input-line-height;
      position: absolute;
      right: 0px;
      top: 1px;
    }

    &.has-value {
      .fake-placeholder {
        display: none;
      }
    }
  }
}

.alert-wrapper {
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  z-index: 0;

  .alert-left {
    bottom: 21px;
    display: block;
    position: absolute;
    left: 0px;
    top: 22px;
    width: 15px;

    &:before {
      display: block;
      content: '';
      height: 22px;
      position: absolute;
      top: -22px;
      width: 15px;
    }

    &:after {
      display: block;
      content: '';
      height: 21px;
      position: absolute;
      bottom: -21px;
      width: 15px;
    }
  }

  .alert-right {
    bottom: 21px;
    display: block;
    position: absolute;
    right: 0px;
    top: 22px;
    width: 15px;

    &:before {
      display: block;
      content: '';
      height: 22px;
      position: absolute;
      top: -22px;
      width: 15px;
    }

    &:after {
      display: block;
      content: '';
      height: 21px;
      position: absolute;
      bottom: -21px;
      width: 15px;
    }
  }

  .alert {
    border: 0px none;
    font-size: 12px;
    line-height: 16px;
    padding: 14px 0px 13px 0px;

    p.text {
      min-height: 16px;
    }
  }

  &.alert-wrapper-success {
    .alert-left {
      background-color: $alert-success-bg;

      &:before {
        @include use-sprite('alert-success-left-before');
      }

      &:after {
        @include use-sprite('alert-success-left-after');
      }
    }

    .alert-right {
      background-color: $alert-success-bg;

      &:before {
        @include use-sprite('alert-success-right-before');
      }

      &:after {
        @include use-sprite('alert-success-right-after');
      }
    }
  }

  &.alert-wrapper-warning {
    .alert-left {
      background-color: $alert-warning-bg;

      &:before {
        @include use-sprite('alert-warning-left-before');
      }

      &:after {
        @include use-sprite('alert-warning-left-after');
      }
    }

    .alert-right {
      background-color: $alert-warning-bg;

      &:before {
        @include use-sprite('alert-warning-right-before');
      }

      &:after {
        @include use-sprite('alert-warning-right-after');
      }
    }
  }

  &.alert-wrapper-danger {
    .alert-left {
      background-color: $alert-danger-bg;

      &:before {
        @include use-sprite('alert-danger-left-before');
      }

      &:after {
        @include use-sprite('alert-danger-left-after');
      }
    }

    .alert-right {
      background-color: $alert-danger-bg;

      &:before {
        @include use-sprite('alert-danger-right-before');
      }

      &:after {
        @include use-sprite('alert-danger-right-after');
      }
    }
  }

  &.alert-wrapper-info {
    .alert-left {
      background-color: $alert-info-bg;

      &:before {
        @include use-sprite('alert-info-left-before');
      }

      &:after {
        @include use-sprite('alert-info-left-after');
      }
    }

    .alert-right {
      background-color: $alert-info-bg;

      &:before {
        @include use-sprite('alert-info-right-before');
      }

      &:after {
        @include use-sprite('alert-info-right-after');
      }
    }
  }
}

.btn-wrapper {
  cursor: pointer;
  display: inline-block;
  padding: 0px 14px;

  .btn-default-short,
  .btn-default {
    height: 44px;
    min-width: 28px;
    outline: 0px none !important;
    position: relative;
    text-align: center;
    width: 150px;
    background-color: $putit-color-blue;
    color: white;
    border-radius: 5px;
    border: 1px solid $putit-color-blue;
    font-weight: bold;

    > img {
      vertical-align: middle;
    }

    &:hover {
      background-color: $putit-color-blue;
      border: 1px solid #091920;
    }
    &:active {
      background-color: #3da8cc;
      border: 2px solid #091920;
      color: #fff;
    }
    &:disabled {
      background-color: #dddddd;
      border-radius: 5px;
      color: #ffffff;
    }
  }

  .btn-default-short {
    height: 45px;
    width: 45px;

    .lock {
      position: absolute;
      right: 6px;
      width: 25px;
      height: 25px;
      top: 9px;
    }
    .lock.open {
      @include use-icon('ICON_UnLock_White');
    }

    .lock.closed {
      @include use-icon('ICON_Lock_White');
      right: 5px;
      top: 8px;
    }

    .save {
      @include use-icon('ICON_Save_White');
      position: absolute;
      right: 11px;
      width: 20px;
      height: 20px;
      top: 14px;
    }
  }

  .btn-white-short,
  .btn-white {
    height: 44px;
    min-width: 28px;
    outline: 0px none !important;
    position: relative;
    text-align: center;
    width: 150px;
    background-color: #fff;
    color: $putit-color-blue;
    border-radius: 5px;
    border: 0px;
    font-weight: bold;

    > img {
      vertical-align: middle;
    }

    &:hover {
      background-color: #fff;
      border: 1px solid $putit-color-blue;
    }
    &:active {
      background-color: #fff;
      border: 1px solid #091920;
      color: $putit-color-blue;
    }
    &:disabled {
      background-color: #fff;
      border-radius: 5px;
      color: #dddddd;
    }
  }
  .btn-white-short {
    height: 45px;
    width: 45px;
    border: 1px solid $putit-color-blue;

    .logs {
      @include use-icon('ICON_Log');
      position: absolute;
      right: 5px;
      width: 31px;
      height: 25px;
      top: 9px;
    }

    .details {
      @include use-icon('ICON_Details');
      position: absolute;
      right: 9px;
      width: 25px;
      height: 25px;
      top: 9px;
    }

    .delete {
      @include use-icon('ICON_Delete_Blue');
      position: absolute;
      right: 14px;
      width: 15px;
      height: 15px;
      top: 14px;
    }

    .date-picker {
      @include use-icon('ICON_DatePicker');
      position: absolute;
      right: 9px;
      width: 25px;
      height: 25px;
      top: 9px;
    }

    .jira {
      @include use-icon('ICON_jira');
      position: absolute;
      right: 10px;
      width: 24px;
      height: 24px;
      top: 11px;
    }
  }
}
.btn-wrapper.no-padding {
  padding: 0;
}

.dropdown-menu {
  .dropdown-menu-wrapper-left {
    background: $dropdown-bg;
    bottom: 23px;
    display: block;
    position: absolute;
    left: 0px;
    top: 21px;
    width: 17px;

    &:before {
      @include use-sprite('dropdown-menu-left-before');
      display: block;
      content: '';
      height: 21px;
      position: absolute;
      top: -21px;
      width: 17px;
    }

    &:after {
      @include use-sprite('dropdown-menu-left-after');
      display: block;
      content: '';
      height: 23px;
      position: absolute;
      bottom: -23px;
      width: 17px;
    }
  }

  .dropdown-menu-wrapper-right {
    background: $dropdown-bg;
    bottom: 23px;
    display: block;
    position: absolute;
    right: 0px;
    top: 21px;
    width: 17px;

    &:before {
      @include use-sprite('dropdown-menu-right-before');
      display: block;
      content: '';
      height: 21px;
      position: absolute;
      top: -21px;
      width: 17px;
    }

    &:after {
      @include use-sprite('dropdown-menu-right-after');
      display: block;
      content: '';
      height: 23px;
      position: absolute;
      bottom: -23px;
      width: 17px;
    }
  }

  .dropdown-menu-inner {
    background: $dropdown-bg;
    margin: 0px 17px;
    padding-top: 7px;
    padding-bottom: 14px;

    > ul {
      margin: 0px;

      li {
        margin: 0px;
        margin-bottom: 14px;

        &:last-child {
          margin-bottom: 0px;
        }

        &.dropdown-header {
          color: $gray-dark;
          font-size: $font-size-base;
          padding: 0px;
          margin-bottom: 10px;
          text-align: center;
        }

        a {
          text-decoration: none;
          display: block;
          line-height: 1;
          padding: 10px 14px;
          outline: 0px none;
          text-align: center;

          &:hover {
            background-color: $gray;
            color: $gray-dark;
          }
        }

        &.disabled {
          a {
            color: $gray;
            cursor: not-allowed;

            &:hover {
              background-color: transparent;
              color: $gray;
            }
          }
        }
      }
    }
  }
}

.label-wrapper {
  display: inline-block;
  height: 22px;
  padding: 0px 7px;

  .label {
    border-radius: 0px;
    display: inline-block;
    height: 22px;
    line-height: 22px;
    min-width: 11px;
    padding: 0px;
    position: relative;
    vertical-align: top;

    &:before,
    &:after {
      content: '';
      display: block;
      height: 22px;
      position: absolute;
      top: 0px;
      width: 7px;
    }

    &:empty {
      display: inline-block;
    }

    &:before {
      left: -7px;
    }

    &:after {
      right: -7px;
    }

    &.label-default {
      &:before {
        @include use-sprite('label-default-before');
      }

      &:after {
        @include use-sprite('label-default-after');
      }
    }

    &.label-primary {
      &:before {
        @include use-sprite('label-primary-before');
      }

      &:after {
        @include use-sprite('label-primary-after');
      }
    }

    &.label-success {
      &:before {
        @include use-sprite('label-success-before');
      }

      &:after {
        @include use-sprite('label-success-after');
      }
    }

    &.label-info {
      &:before {
        @include use-sprite('label-info-before');
      }

      &:after {
        @include use-sprite('label-info-after');
      }
    }

    &.label-warning {
      &:before {
        @include use-sprite('label-warning-before');
      }

      &:after {
        @include use-sprite('label-warning-after');
      }
    }

    &.label-danger {
      &:before {
        @include use-sprite('label-danger-before');
      }

      &:after {
        @include use-sprite('label-danger-after');
      }
    }
  }
}

.breadcrumb {
  border-bottom: 1px solid $putit-color-blue;
  padding-top: 20px;

  a {
    padding-left: 10px;
    padding-right: 10px;
    font-size: medium;
  }
}

pre {
  background: white;
  color: $text-color;
  font-size: 12px;
  line-height: 14px;
}

.glyphicon {
  &.glyphicon-primary {
    color: $brand-primary;
  }

  &.glyphicon-success {
    color: $brand-success;
  }

  &.glyphicon-info {
    color: $brand-info;
  }

  &.glyphicon-warning {
    color: $brand-warning;
  }

  &.glyphicon-danger {
    color: $brand-danger;
  }
}

.form-control {
  border-radius: 5px;
  background-color: #fff;
}

.search-input {
  .input-wrapper {
    position: relative;
    .ICON_Finder {
      @include use-icon('ICON_Finder');
      position: absolute;
      right: 20px;
      width: 20px;
      height: 20px;
      top: 11px;
    }
  }
}

.jira-avatar {
  @include use-icon('ICON_Jira_viewavatar');
  background-size: 20px;
  display: block;
  position: relative;
  width: 20px;
  height: 20px;
  top: 20px;
}
