@import 'vendor/_bootstrap';
@import 'vendor/react-custom-popup';

/*!
 * Putit Web | Copyright 2018-present Putit Team <info@putit.io> | Apache 2.0 License
 * https://github.com/putit/putit-web
 */

@import '_variables';
@import '_mixins';

@import 'oxygen';
@import 'type';

@import 'theme';

@import 'components/AppTable';
@import 'components/Forms';
@import 'components/FullWindowContainer';
@import 'components/Loader';
@import 'components/Modal';
@import 'components/Popup';
@import 'components/SegmentedControl';

@import 'blueprints/auth/index';
@import 'blueprints/core/index';
@import 'blueprints/dev/index';
@import 'blueprints/rc_dashboard/index';
@import 'blueprints/setup_wizard/index';
@import 'blueprints/app_dashboard/AppDashboardView';
@import 'blueprints/dashboard/DashboardView';
