/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.putit-release-jira-popup {
  left: 96px;
  top: 58px;
  width: 82%;
  border-radius: 5px;
  overflow: hidden;
  .modal-content {
    .modal-body {
      background-color: #fff;
      .putit-table {
        font-family: 'Oxygen Bold';

        .doublewidth {
          width: $app_table_column_width * 2;
        }

        .triplewidth {
          width: $app_table_column_width * 3;
        }

        .no-border {
          .row {
            border-bottom: none;
            margin-bottom: 20px;
          }
        }

        .details {
          .grey-text {
            color: #b6b6b6;
            text-transform: uppercase;
          }
          .cell {
            font-size: small;
            line-height: 15px;
          }
          .header {
            .row {
              margin-bottom: 0px;
              height: 25px;
            }
          }
          .data {
            .row {
              padding-top: 1px;
              margin-bottom: 0px;
              height: 35px;
            }
            .cell {
              font-family: 'Oxygen Regular';
            }
          }
        }

        .row {
          border-bottom: 1px solid #e4e4e4;

          .btn-wrapper {
            padding-right: 2px;
          }
        }

        hr {
          border-top: 1px solid #e4e4e4;
        }

        .header-details {
          height: 15px;
        }

        .jira-status {
          outline: 1px solid $putit-color-blue;
          outline-offset: 3px;
          border-radius: 3px;
          color: $putit-color-blue;
        }
      }
    }
  }
}
