/**
 * Putit Web
 * Copyright 2018-present Putit Team <info@putit.io>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.putit-modal-button {
  display: inline-block;
  height: 22px;
  margin-right: 6px;
  padding: 0px 14px;
  position: relative;

  &:before,
  &:after {
    content: '';
    display: block;
    height: 22px;
    position: absolute;
    top: 0px;
    width: 14px;
  }

  &:before {
    @include use-sprite('putit-modal-button-normal-before');
    left: 0px;
  }

  &:after {
    @include use-sprite('putit-modal-button-normal-after');
    right: 0px;
  }

  &:last-child {
    margin-right: 0px;
  }

  > button {
    background: $gray-lighter;
    border: 0px none;
    border-radius: 0px;
    color: $gray-dark;
    display: block;
    font-size: 14px;
    line-height: 22px;
    outline: 0px none;
    padding: 0px;
    text-align: center;
    text-transform: uppercase;
    width: 228px;
  }

  &:not([class~='disabled']) {
    &:hover {
      cursor: pointer;
      &:before {
        @include use-sprite('putit-modal-button-hover-before');
      }

      &:after {
        @include use-sprite('putit-modal-button-hover-after');
      }

      > button {
        background: $gray-light;
      }
    }
  }

  &.disabled {
    cursor: $cursor-disabled;

    > button {
      cursor: $cursor-disabled;
    }
  }
}
